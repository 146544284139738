<template>
    <span>FormContratto</span>
</template>

<script>
    export default {
        name: 'FormContratto'
    }
</script>

<style scoped>

</style>
